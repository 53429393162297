import { routerNames } from "./routerNames";
import { requiredAuth, notRequiredAuth, checkConfirmAccount, checkRole } from "./guards";

import { MainLayout, PersonalLayout, AdminLayout } from "@/widgets/layouts";
import { USER_ROLE } from "@/shared/lib/types";

export const routes = [
    {
        path: "/",
        component: MainLayout,
        children: [
            {
                path: "",
                name: routerNames.MAIN,
                component: () => import("@/pages/Main.vue"),
                meta: {
                    title: "Главная",
                },
            },
            {
                path: "payment-result",
                name: routerNames.PAYMENT_RESULT,
                component: () => import("@/pages/public/PaymentResult.vue"),
                meta: {
                    title: "Результат оплаты",
                },
            },
            {
                path: "/delivery-and-payment",
                name: routerNames.DELIVERY_AND_PAYMENT,
                component: () => import("@/pages/public/DeliveryAndPayment.vue"),
                meta: {
                    title: "Доставка и оплата",
                },
            },
            {
                path: "/contacts",
                name: routerNames.CONTACTS,
                component: () => import("@/pages/public/Contacts.vue"),
                meta: {
                    title: "Контакты",
                },
            },
            {
                path: "/terms",
                name: routerNames.TERMS,
                component: () => import("@/pages/public/Terms.vue"),
                meta: {
                    title: "Публичная оферта",
                },
            },
            {
                path: "/policy",
                name: routerNames.POLICY,
                component: () => import("@/pages/public/Policy.vue"),
                meta: {
                    title: "Политика конфидициальности",
                },
            },
            {
                path: "/accept-policy",
                name: routerNames.ACCEPT_POLICY,
                component: () => import("@/pages/public/AcceptPolicy.vue"),
                meta: {
                    title: "Обработка персональных данных",
                },
            },
            {
                path: "/return-goods",
                name: routerNames.RETURN_GOODS,
                component: () => import("@/pages/public/ReturnGoods.vue"),
                meta: {
                    title: "Возврат товара",
                },
            },
            {
                path: "/order/:id",
                name: routerNames.ORDER,
                component: () => import("@/pages/public/Order.vue"),
                meta: {
                    title: "Мой заказ",
                },
            },
        ],
    },
    {
        path: "/",
        component: MainLayout,
        beforeEnter: notRequiredAuth,
        children: [
            {
                path: "signin",
                name: routerNames.SIGNIN,
                component: () => import("@/pages/auth/Signin.vue"),
                meta: {
                    title: "Вход",
                },
            },
            {
                path: "signup",
                name: routerNames.SIGNUP,
                component: () => import("@/pages/auth/Signup.vue"),
                meta: {
                    title: "Регистрация",
                },
            },
            {
                path: "forgot-password",
                name: routerNames.FORGOT_PASSWORD,
                component: () => import("@/pages/auth/ForgotPassword.vue"),
                meta: {
                    title: "Восстановление пароля",
                },
            },
            {
                path: "reset-password/:id",
                name: routerNames.RESET_PASSWORD,
                component: () => import("@/pages/auth/ResetPassword.vue"),
                meta: {
                    title: "Сброс пароля",
                },
            },
        ],
    },
    {
        path: "/personal",
        component: PersonalLayout,
        children: [
            {
                path: "",
                beforeEnter: [requiredAuth, checkRole, checkConfirmAccount],
                meta: {
                    role: USER_ROLE.CLIENT,
                },
                children: [
                    {
                        path: "",
                        name: routerNames.PERSONAL_HOME,
                        component: () => import("@/pages/personal/Home.vue"),
                        meta: {
                            title: "Личный кабинет",
                        },
                    },
                    {
                        path: "orders/unpaid",
                        name: routerNames.PERSONAL_ORDERS_UNPAID,
                        component: () => import("@/pages/personal/orders/UnpaidOrders.vue"),
                        meta: {
                            title: "Неоплаченные заказы",
                        },
                    },
                    {
                        path: "orders/paid",
                        name: routerNames.PERSONAL_ORDERS_PAID,
                        component: () => import("@/pages/personal/orders/PaidOrders.vue"),
                        meta: {
                            title: "Мои заказы",
                        },
                    },
                    {
                        path: "bonus",
                        name: routerNames.PERSONAL_BONUS,
                        component: () => import("@/pages/personal/bonus/Bonus.vue"),
                        meta: {
                            title: "Бонусная система",
                        },
                    },
                    {
                        path: "delivery",
                        name: routerNames.PERSONAL_DELIVERY,
                        component: () => import("@/pages/personal/delivery/Delivery.vue"),
                        meta: {
                            title: "Способы доставки",
                        },
                    },
                    {
                        path: "profile",
                        name: routerNames.PERSONAL_PROFILE,
                        component: () => import("@/pages/personal/profile/Profile.vue"),
                        meta: {
                            title: "Профиль",
                        },
                    },
                ],
            },
            {
                path: "activation",
                name: routerNames.PERSONAL_ACTIVATION,
                component: () => import("@/pages/personal/system/Activation.vue"),
                beforeEnter: [requiredAuth],
                meta: {
                    title: "Активация аккаунта",
                },
            },
        ],
    },
    {
        path: "/admin",
        component: AdminLayout,
        beforeEnter: [requiredAuth, checkRole],
        meta: {
            role: USER_ROLE.ADMIN,
        },
        children: [
            {
                path: "purchase",
                name: routerNames.ADMIN_PURCHASE,
                component: () => import("@/pages/admin/purchase/AdminPurchase.vue"),
                meta: {
                    title: "Закупки",
                },
            },
            {
                path: "bonus",
                redirect: { name: routerNames.ADMIN_BONUS_EXCEPTION },
                name: routerNames.ADMIN_BONUS,
                children: [
                    {
                        path: "settings",
                        name: routerNames.ADMIN_BONUS_SETTINGS,
                        component: () => import("@/pages/admin/bonus/AdminBonus.vue"),
                        meta: {
                            title: "Бонусная программа",
                        },
                    },
                    {
                        path: "exception",
                        name: routerNames.ADMIN_BONUS_EXCEPTION,
                        component: () => import("@/pages/admin/bonus/AdminBonusException.vue"),
                        meta: {
                            title: "Бонусная программа",
                        },
                    },
                ],
            },
            {
                path: "users",
                name: routerNames.ADMIN_USER,
                component: () => import("@/pages/admin/user/AdminUser.vue"),
                meta: {
                    title: "Пользователи",
                },
            },
        ],
    },
];
